.stage-container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    display: block;
    justify-content: center;

    @media screen and (min-width: 500px) {
        padding-right: 100px;
        padding-left: 100px;
        display: flex;
    }

    .stage {
        flex-grow: 1;
        div {
            text-align: left;
        }
        text-align: left;
        .title-zone {
            text-align: left;
            padding: 1rem;
            h1,
            h2 {
                color: #000;
            }
            h1 {
                display: flex;
                align-items: center;
                margin-bottom: 0;
                padding-bottom: 0;
            }
            .country-flag {
                width: 35px;
                margin-right: 1rem;
            }
        }
        .stat-box {
            display: flex;
            flex-direction: column;
            @media screen and (min-width: 450px) {
                flex-direction: row;
            }
            img {
                @media screen and (min-width: 450px) {
                    width: 30%;
                    min-width: 8rem;
                }
            }
            .country-stats {
                border-top: 1px solid;
                margin-top: 1rem;
                padding-top: 0.5rem;

                @media screen and (min-width: 450px) {
                    margin-left: 1rem;
                    margin-top: 0;
                    padding-top: 0;
                    border-top: none;
                }
            }
        }
        .stage-left {
            display: flex;
            flex-direction: column;
            margin-bottom: 2rem;
            margin-right: 5rem;
        }
        .stage-right {
            display: inline-block;
        }
    }
}
